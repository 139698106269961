import React from "react"
import { Link } from "gatsby"
import urlBuilder from "@sanity/image-url"
import styled from "@emotion/styled"

function FeaturedWeddings({ title, weddings }) {
  const urlFor = (source) =>
    urlBuilder({ projectId: "yk6mnr40", dataset: "production" }).image(source)

  const FeaturedWeddingsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  `

  const FeaturedWeddingWrapper = styled.div`
    position: relative;

    &:hover img {
      transform: scale(1.3);
    }
  `

  const FeaturedWeddingImage = styled.div`
    margin-bottom: var(--size-1);
    overflow: hidden;

    img {
      transition: all 0.5s ease-in-out;
    }

    &:after {
      content: "";
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: rgba(72, 69, 45, 0.4);
      mix-blend-mode: multiply;
      transition: opacity 0.5s ease-in-out;
      ${FeaturedWeddingWrapper}:hover & {
        opacity: 0;
      }
    }
  `

  const FeaturedWeddingHover = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out;
    ${FeaturedWeddingWrapper}:hover & {
      opacity: 0;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: calc(25% - 20px);
      height: calc(25% - 20px);
      right: 20px;
      bottom: 20px;
      border-bottom: 1px solid var(--colour-white);
      border-right: 1px solid var(--colour-white);
      opacity: 70%;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: calc(25% - 20px);
      height: calc(25% - 20px);
      left: 20px;
      top: 20px;
      border-top: 1px solid var(--colour-white);
      border-left: 1px solid var(--colour-white);
      opacity: 70%;
    }
  `

  const FeauredWeddingText = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--colour-white);
    text-align: center;
    font-family: var(--font-family-heading);
    font-size: var(--size-4);
    font-weight: 700;
    line-height: var(--line-height-heading);
    transition: opacity 0.5s ease-in-out;
    ${FeaturedWeddingWrapper}:hover & {
      opacity: 0;
    }
  `

  return (
    <>
      <h2>{title}</h2>
      <FeaturedWeddingsWrapper>
        {weddings.map((wedding, index) => (
          <Link to={`/wedding/${wedding.slug.current}`} key={index}>
            <FeaturedWeddingWrapper key={index}>
              <FeaturedWeddingImage key={index}>
                <img
                  key={index}
                  sizes="
                  (min-width: 1032px) calc(238px + 4px),
                  (min-width: 800px) calc(25vw - 4px - 16px),
                  (min-width: 376px) calc(50vw - 16px - 8px),
                  100vw"
                  srcSet={[
                    urlFor(wedding.weddingImageHero.imageUpload.asset.id)
                      .auto("format")
                      .width(750)
                      .height(750)
                      .fit("crop")
                      .url() + ` 750w`,
                    urlFor(wedding.weddingImageHero.imageUpload.asset.id)
                      .auto("format")
                      .width(476)
                      .height(476)
                      .fit("crop")
                      .url() + ` 476w`,
                    urlFor(wedding.weddingImageHero.imageUpload.asset.id)
                      .auto("format")
                      .width(238)
                      .height(238)
                      .fit("crop")
                      .url() + ` 238w`,
                  ]}
                  src={urlFor(wedding.weddingImageHero.imageUpload.asset.id)
                    .auto("format")
                    .width(238)
                    .height(238)
                    .fit("crop")
                    .url()}
                  alt=""
                />
              </FeaturedWeddingImage>
              <FeaturedWeddingHover />
              <FeauredWeddingText>{wedding.title}</FeauredWeddingText>
            </FeaturedWeddingWrapper>
          </Link>
        ))}
      </FeaturedWeddingsWrapper>
    </>
  )
}

export default FeaturedWeddings
