import React from "react"
// import Carousel, {
//   slidesToShowPlugin,
//   autoplayPlugin,
//   Dots,
// } from "@brainhubeu/react-carousel"
// import "@brainhubeu/react-carousel/lib/style.css"
import urlBuilder from "@sanity/image-url"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Global, css } from "@emotion/core"
import "flickity/css/flickity.css"
import Flickity from "react-flickity-component"

const flickityOptions = {
  wrapAround: true,
  setGallerySize: false,
  // lazyLoad: 2,
  // autoPlay: true,
}

const Carousel = ({ sliderImages }) => {
  const urlFor = (source) =>
    urlBuilder({ projectId: "yk6mnr40", dataset: "production" }).image(source)
  return (
    <>
      <Global
        styles={css`
          .carousel {
            height: 70vh;
          }
          .carouselCell {
            width: 100%;
            height: 100%;
            margin: 0 2px;
            @media (min-width: 900px) {
              width: 66%;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        `}
      />
      <Flickity
        className={"carousel"}
        elementType={"div"}
        options={flickityOptions}
        disableImagesLoaded={false}
        // infiniteLoop
        // centerMode
        // centerSlidePercentage={60}
        // showThumbs={false}
        // useKeyboardArrows={true}
        // showIndicators={false}
        // showStatus={false}
      >
        {sliderImages.map((image, index) => (
          <div className="carouselCell" key={index}>
            <img
              key={index}
              sizes="(min-width: 800px) 800px, 100vw"
              srcSet={[
                urlFor(image.imageUpload.asset.id)
                  .auto("format")
                  .width(3200)
                  .url() + ` 3200w`,
                urlFor(image.imageUpload.asset.id)
                  .auto("format")
                  .width(1600)
                  .url() + ` 1600w`,
                urlFor(image.imageUpload.asset.id)
                  .auto("format")
                  .width(800)
                  .url() + ` 800w`,
              ]}
              src={urlFor(image.imageUpload.asset.id)
                .auto("format")
                .width(800)
                .url()}
              alt={image.imageDescription}
            />
          </div>
        ))}
      </Flickity>
    </>
  )
}

export default Carousel
